import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import pickup from '../assets/images/pickup.svg';
import delivery from '../assets/images/delivery.svg';
import transit from '../assets/images/transit.svg';
import { PICKUP, DELIVERY, TRANSPORT } from '../constants';

const isTranportPinZoomed = (selectedShipment) => {
  if (selectedShipment && selectedShipment.pinZoomed === TRANSPORT) {
    return true;
  } else {
    return false;
  }
};

const isPickupPinZoomed = (selectedShipment) => {
  if (selectedShipment && selectedShipment.pinZoomed === PICKUP) {
    return true;
  } else {
    return false;
  }
};

const isDeliveryPinZoomed = (selectedShipment) => {
  if (selectedShipment && selectedShipment.pinZoomed === DELIVERY) {
    return true;
  } else {
    return false;
  }
};

const documentBody = document.getElementsByTagName('body')[0];

// added to allow map center change dinamically
const ChangeView = ({ bounds }) => {
  const {
    windowState: { isMobileView },
    homeState: { selectedShipment },
  } = useMst();
  const mapPadding = isMobileView() ? [0, 0] : [100, 100];
  const map = useMap();

  if (selectedShipment && selectedShipment.fullScreenMap) {
    map.getBoundsZoom(bounds, true);
    // @ts-ignore
    map.fitBounds(bounds, { padding: mapPadding });
  } else if (isTranportPinZoomed(selectedShipment)) {
    const center = L.latLng(
      Number(selectedShipment.transport.lat),
      Number(selectedShipment.transport.long),
    );
    map.flyTo(center, 12);
  } else if (isPickupPinZoomed(selectedShipment)) {
    const center = L.latLng(
      Number(selectedShipment.pickup_location.lat),
      Number(selectedShipment.pickup_location.long),
    );
    map.flyTo(center, 12);
  } else if (isDeliveryPinZoomed(selectedShipment)) {
    const center = L.latLng(
      Number(selectedShipment.delivery_location.lat),
      Number(selectedShipment.delivery_location.long),
    );
    map.flyTo(center, 12);
  } else {
    map.getBoundsZoom(bounds, true);
    // @ts-ignore
    map.fitBounds(bounds, { padding: mapPadding });
  }

  // disable page scrolling while shipment tracking is active
  if (!!selectedShipment) {
    window.scrollTo(0, 0);
    map.scrollWheelZoom.enable();
    documentBody.classList.add('scroll-disabled');
  } else {
    map.scrollWheelZoom.disable();
    documentBody.classList.remove('scroll-disabled');
  }

  return null;
};

const Map = () => {
  const {
    homeState: { selectedShipment },
    windowState: { isMobileOrTablet, isMobileView },
  } = useMst();

  const pickupIcon = new L.Icon({
    iconUrl: pickup,
    iconRetinaUrl: pickup,
    iconSize: isPickupPinZoomed(selectedShipment) ? [63, 80] : [42, 60],
    className: `marker ${isPickupPinZoomed(selectedShipment) ? 'active' : ''}`,
  });

  const deliveryIcon = new L.Icon({
    iconUrl: delivery,
    iconRetinaUrl: delivery,
    iconSize: isDeliveryPinZoomed(selectedShipment) ? [63, 80] : [42, 60],
    className: `marker ${
      isDeliveryPinZoomed(selectedShipment) ? 'active' : ''
    }`,
  });

  const currentIcon = new L.Icon({
    iconUrl: transit,
    iconRetinaUrl: transit,
    iconSize: isTranportPinZoomed(selectedShipment) ? [63, 80] : [42, 60],
    className: `marker ${
      isTranportPinZoomed(selectedShipment) ? 'active' : ''
    }`,
  });

  // los angeles & chicago
  const defaultCenter = [
    [34.3155072, -118.2096814],
    [41.881832, -87.623177],
  ];

  const corner1 = L.latLng(
    selectedShipment
      ? Number(selectedShipment.pickup_location.lat)
      : defaultCenter[0][0],
    selectedShipment
      ? Number(selectedShipment.pickup_location.long)
      : defaultCenter[0][1],
  );
  const corner2 = L.latLng(
    selectedShipment
      ? Number(selectedShipment.delivery_location.lat)
      : defaultCenter[1][0],
    selectedShipment
      ? Number(selectedShipment.delivery_location.long)
      : defaultCenter[1][1],
  );

  const bounds = L.latLngBounds(corner1, corner2);

  const getBounds = () => {
    if (bounds) {
      return bounds;
    }
  };

  const zoomPin = (type) => {
    if (!isMobileView()) {
      selectedShipment.setPinZoomed(null);
      selectedShipment.setFullScreenMap(false);
      selectedShipment.setPinZoomed(type);
    }
  };

  return (
    <MapContainer
      // @ts-ignore
      className="map"
      bounds={getBounds()}
      dragging={!isMobileOrTablet()}
    >
      {/* added to allow map center change dinamically */}
      <ChangeView bounds={getBounds()} />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {selectedShipment && selectedShipment.pickup_location && (
        <Marker
          position={[
            Number(selectedShipment.pickup_location.lat),
            Number(selectedShipment.pickup_location.long),
          ]}
          icon={pickupIcon}
          eventHandlers={{
            click: (e) => {
              zoomPin(PICKUP);
            },
          }}
        ></Marker>
      )}
      {selectedShipment && selectedShipment.delivery_location && (
        <Marker
          position={[
            Number(selectedShipment.delivery_location.lat),
            Number(selectedShipment.delivery_location.long),
          ]}
          icon={deliveryIcon}
          eventHandlers={{
            click: (e) => {
              zoomPin(DELIVERY);
            },
          }}
        ></Marker>
      )}
      {selectedShipment && selectedShipment.hasTransportObject && (
        <Marker
          position={[
            Number(selectedShipment.transport.lat),
            Number(selectedShipment.transport.long),
          ]}
          icon={currentIcon}
          eventHandlers={{
            click: (e) => {
              zoomPin(TRANSPORT);
            },
          }}
        ></Marker>
      )}
    </MapContainer>
  );
};

export default observer(Map);
