import { types } from 'mobx-state-tree';
import { MenuItemModel } from './MenuItemModel';
import { menuItems } from './MenuItems';
import { Events } from 'react-scroll';
import { ABOUT_US } from '../constants';

export const MenuModel = types
  .model('MenuModel', {
    menuItems: types.map(MenuItemModel),
    mobileMenuOpen: false,
  })
  .views((self) => {
    return {
      get menuItemsArray() {
        return Array.from(self.menuItems.values());
      },
    };
  })
  .actions((self) => {
    return {
      toggleMobileMenuOpen() {
        self.mobileMenuOpen = !self.mobileMenuOpen;
      },
      closeMobileMenu() {
        self.mobileMenuOpen = false;
      },
      afterCreate() {
        this.setItems(menuItems);
      },
      setItems(items) {
        Object.values(items).forEach((item) => {
          self.menuItems.put(item);
        });
      },
      handleItemClick(title) {
        const menu = this;
        Events.scrollEvent.register('end', function(to, element) {
          if (title === ABOUT_US) {
            menu.setActiveItem(title);
          }
        });
      },
      setActiveItem(title) {
        const activeItem = self.menuItemsArray.find(
          (item) => title === item.title,
        );
        self.menuItems.forEach((item) => {
          item.setActive(false);
        });
        this.closeMobileMenu();
        activeItem.setActive(true);
      },
    };
  });
