import React from 'react';
import { observer } from 'mobx-react';
import { SEARCHING_SHIPMENT_TEXT } from '../constants';

const Loader = () => {
  return (
    <div className="loader">
      <h3 className="fw500">{SEARCHING_SHIPMENT_TEXT}</h3>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default observer(Loader);
