export const TITLE_404 = '404 - PAGE NOT FOUND';
export const TEXT_404 =
  'Data you are looking for might not exist, has been deleted or is temporarily unavailable.';
export const GO_BACK = 'Go back';
export const PENTAGON_LOGISTICS = 'Pentagon Logistics';
export const AND = 'and';
export const TAS_TRANSPORT = 'TAS Transport LTD';

export const PAGE_NOT_AVAILABLE_YET_TITLE = 'Page not available';
export const PAGE_NOT_AVAILABLE_YET_TEXT =
  'This page is not available yet. Please come back later.';

export const EMAIL_ERROR_TEXT = 'Invalid email address';
export const USER_NOT_FOUND_ERROR_TEXT = 'User not found';
export const BACK = 'Back';
export const NEXT = 'Next';

export const HOME = 'Home';
export const SERVICES = 'Services';
export const ABOUT_US = 'About Us';
export const CONTACT = 'Contact';

// export const ADDRESS = [
//   'Pentagon Logistics Inc',
//   '2525 Haines Rd.',
//   'Mississauga, ON',
//   'L4Y 1Y7, Canada',
// ];
export const ADDRESS = [
  'Pentagon Logistics Inc',
  '7045 Millcreek Drive',
  'Mississauga, ON',
  'L5N 3R3, Canada',
];
export const PHONE = ['Telephone', '905-677-6700', 'Toll Free: 1-866-691-6700'];
export const OPERATIONS_MAIL_TITLE = 'Operations Sales & Logistics';
export const OPERATIONS_MAIL_TEXT = 'info@pentagonlogistics.ca';
export const SAFETY_MAIL_TITLE = 'Safety Training & Equipment';
export const SAFETY_MAIL_TEXT = 'safety@pentagonlogistics.ca';
export const ACCOUNTS_PAYABLE_MAIL_TITLE = 'Account Payable';
export const ACCOUNTS_PAYABLE_MAIL_TEXT = 'accounting@pentagonlogistics.ca';
export const ACCOUNTS_RECEIVABLE_MAIL_TITLE = 'Account Receivable';
export const ACCOUNTS_RECEIVABLE_MAIL_TEXT = 'info@pentagonlogistics.ca';
export const BILLING_MAIL_TITLE = 'Billing Information';
export const BILLING_MAIL_TEXT = 'info@pentagonlogistics.ca';
export const DISPATCH_MAIL_TITLE = 'Dispatch Operations';
export const DISPATCH_MAIL_TEXT = 'dispatch@pentagonlogistics.ca';
export const FLATBED_MAIL_TITLE = 'Flatbed Operations';
export const FLATBED_MAIL_TEXT = 'dispatch@pentagonlogistics.ca';
export const FOR = 'For';
export const GENERAL_INFO = 'General Information';
export const PLEASE_EMAIL = 'please email ';
export const GENERAL_EMAIL = 'info@pentagonlogistics.ca';

export const BACK_TO_TOP = 'Back to top';

export const PLATFORM_ENTRY_TITLE = 'Transport Management Platform';
export const PLATFORM_ENTRY_TEXT_1 =
  'KamionSCM is committed to being a leading provider of exceptional transportation and logistics services.';
export const PLATFORM_ENTRY_TEXT_2 =
  'Providing exceptional transportation and logistics services.';
export const PLATFORM_ENTRY_TEXT_3 = 'Partners: ';

export const LEARN_MORE = 'Learn more';
export const GET_STARTED = 'Get started';

export const TRACK_MODAL_TEXT =
  "At Pentagon Logistics, we understand that getting your cargo to its destination and knowing where it is at all times is very important. That's why we offer our clients the ability to view GPS Tracking info on the trucks carrying their cargo. Whether it's a refrigerated load, dry van/LTL/TL, Flat Bed, Step Deck or Oversized load, you'll know where it is at all times.";
export const TRACK_MODAL_TEXT_SHORT =
  "At Pentagon Logistics, we understand that getting your cargo to its destination and knowing where it is at all times is very important. That's why we offer our clients the ability to view GPS Tracking info on the trucks carrying their cargo.";
export const TRACK = 'Track';
export const TRACKING_INPUT_PLACEHOLDER = 'Enter Probill No.';
export const TRACKING_INPUT_EMAIL_PLACEHOLDER = 'Email address';
export const TRACKING_INPUT_PHONE_NUMBER_PLACEHOLDER = 'Phone Number';
export const TRACK_MODAL_PHONE_NUMBER_ERROR_TEXT =
  'Please enter a valid phone number.';
export const TRACK_MODAL_NUMBER_ERROR_TEXT =
  'Please enter a valid tracking number';

export const PICKUP_LOCATION = 'Pickup location:';
export const DELIVERY_LOCATION = 'Delivery location:';
export const CURRENT_LOCATION = 'Current location:';
export const STATUS = 'Status: ';
export const PROBILL_NO = 'Probill NO.: ';
export const CURRENTLY_TRACKING = 'Currently tracking';
export const STOP_TRACKING = 'Stop tracking';
export const ESTIMATED_TIME = 'Estimated time: ';
export const PICKUP = 'Pickup';
export const DELIVERY = 'Delivery';
export const CURRENT = 'Current';
export const PICKUP_TIME = 'Pickup time';
export const DELIVERY_TIME = 'Delivery time';
export const COORDINATES = 'Coordinates';
export const COORDINATES_COPIED_TEXT = 'Coordinates copied to clipboard!';

export const SHIPMENT_NOT_FOUND_TITLE =
  'Shipment you are looking for could not be found.';
export const SHIPMENT_NOT_FOUND_TEXT =
  'Please check if you entered correct data.';
export const SHIPMENT_NOT_FOUND_BUTTON = 'Back to tracking';
export const SEARCHING_SHIPMENT_TEXT = 'We are searching for your shipment...';
export const ZOOM_OUT = 'Zoom out';
export const TRANSPORT = 'Transport';

export const PICKUP_POPUP_TITLE = 'Your order is scheduled for pickup';
export const PICKUP_POPUP_TEXT =
  'Order has been received and is ready for pickup by our drivers. Once the packege is picked up the map will be updated.';
export const DELIVERY_POPUP_TITLE = 'Your order is now delivered';
export const DELIVERY_POPUP_TEXT =
  'Your order is successfuly delivered to your location.';
export const TRANSIT_POPUP_TITLE = 'Your order is now in transit';
export const TRANSIT_POPUP_TEXT =
  "Our drivers have picked up the package and it's on its way to you. Orange pin on the map represents current location of the package.";
export const TRANSIT_FALLBACK_POPUP_TEXT =
  'Your order is now in transit, estimated delivery time is: ';
export const SHIPMENT_POPUP_BUTTON_TEXT = 'See map';

export const NOT_AVAILABLE = 'N/A';
export const CUSTOMER_LOGIN = 'Customer login Freight Assist';
export const COMING_SOON = 'Coming soon';

