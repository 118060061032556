import React from 'react';
import { observer } from 'mobx-react';

const AboutCard = (props) => {
  const item = { ...props.item };
  return (
    <div className="about-card">
      <div className="icon-wrapper">
        {item.icon && <img className="w100" src={item.icon} alt={item.title} />}
      </div>
      <div className="text-wrapper">
        <h2>{item.title}</h2>
        <p>{item.text}</p>
      </div>
    </div>
  );
};

export default observer(AboutCard);
