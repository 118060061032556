import React from 'react';
import arrowUp from '../assets/images/to-top.svg';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import { BACK_TO_TOP } from '../constants';

const ToTop = () => {
  const {
    homeState: { scrollToTop },
  } = useMst();

  return (
    <div className="to-top" onClick={scrollToTop}>
      <img src={arrowUp} alt="arrow up" />
      <span className="text">{BACK_TO_TOP}</span>
    </div>
  );
};

export default observer(ToTop);
