import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import AboutCard from './AboutCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Element } from 'react-scroll';
import { ABOUT_US } from '../constants';

const About = () => {
  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const {
    homeState: { aboutItemsArray },
    menuState: { menuItemsArray },
  } = useMst();

  const chunkedArray = aboutItemsArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  const section = menuItemsArray.find((item) => {
    return item.title === ABOUT_US;
  });

  return (
    <Element className="about section" name={section.name} id={section.name}>
      <div className="wrapper">
        <div className="isWiderThanMobile about-content">
          {aboutItemsArray.map((item) => {
            return <AboutCard key={item.id} item={item} />;
          })}
        </div>
        <div className="isMobileView">
          <Slider {...sliderSettings}>
            {chunkedArray.map((arr, index) => {
              return (
                <div key={index} className="doubleSlide">
                  <AboutCard item={arr[0]} />
                  {arr.length > 1 && <AboutCard item={arr[1]} />}
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </Element>
  );
};

export default observer(About);
