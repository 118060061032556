import { HOME, SERVICES, ABOUT_US, CONTACT } from '../constants';

export const menuItems = {
  '1': {
    id: '1',
    title: HOME,
    name: 'homeSection',
    active: true,
  },
  '2': {
    id: '2',
    title: SERVICES,
    name: 'servicesSection',
    active: false,
  },
  '3': {
    id: '3',
    title: ABOUT_US,
    name: 'aboutSection',
    active: false,
  },
  '4': {
    id: '4',
    title: CONTACT,
    name: 'contactSection',
    active: false,
  },
};
