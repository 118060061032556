import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import { STOP_TRACKING, CURRENTLY_TRACKING, PROBILL_NO } from '../constants';

const StopTrackingModal = () => {
  const {
    homeState: { setSelectedShipment, selectedShipment },
  } = useMst();

  const closeStopTrackModal = () => {
    setSelectedShipment(null);
  };

  return (
    <div className="stop-tracking-component">
      <div className="isWiderThanMobile stop-tracking-modal justify-between align-center">
        <div className="info-wrapper">
          <div>
            <span>{CURRENTLY_TRACKING} </span>
            <span>{PROBILL_NO}</span>
            <span className="fw700">{selectedShipment.probil_id}</span>
          </div>
        </div>
        <button className="btn transparent fw700" onClick={closeStopTrackModal}>
          {STOP_TRACKING}
        </button>
      </div>
      <div className="isMobileView stop-tracking-button-wrapper">
        <button className="btn default" onClick={closeStopTrackModal}>
          {STOP_TRACKING}
        </button>
      </div>
    </div>
  );
};

export default observer(StopTrackingModal);
