import mission from '../assets/images/about/mission.svg';
import solution from '../assets/images/about/solution.svg';
import team from '../assets/images/about/team.svg';
import values from '../assets/images/about/values.svg';
import vision from '../assets/images/about/vision.svg';

export const aboutItems = {
  '1': {
    id: '1',
    icon: solution,
    title: 'The Solution',
    text:
      'Pentagon Logistics is committed to being a leading provider of exceptional transportation and logistics services. We go the extra mile and take pride in our strong collaborative work environment that ensures superior customer service in all our transportation and logistics solutions.',
  },
  '2': {
    id: '2',
    icon: vision,
    title: 'Our Vision',
    text:
      'To become an industry leader by providing innovative and cost-effective transportation solutions through unparalleled customer service. Our aim is that all our stakeholders, both internal and external, value Pentagon logistics as their ideal choice in who they partner in business with.',
  },
  '3': {
    id: '3',
    icon: mission,
    title: 'Our Mission',
    text:
      "Pentagon Logistics' mission is to be a leading provider of superior transportation and logistics services.  We continue to reach our goal by exceeding our customer's expectations through our five point service approach coupled with our innovative and ever-evolving transportation solutions.",
  },
  '4': {
    id: '4',
    icon: values,
    title: 'Our Values',
    text:
      "Pentagon Logistics was founded in 2006.  Our operations are guided by our five point service approach, responsibility, reliability, integrity, commitment to safety, and professionalism. These values are key to our success and to our customer's satisfaction.",
  },
  '5': {
    id: '5',
    icon: team,
    title: 'The Team',
    text:
      'Founded on an enthusiastic and dedicated team of professionals who share a common passion for transportation, logistics and superior customer service. We treat all members of our team like our customers, with respect, honesty, professionalism, and in compliance with expected ethical workplace principles.',
  },
};
