import transport from '../assets/images/services/transport.svg';
import temperature from '../assets/images/services/temperature.svg';
import flatbed from '../assets/images/services/flatbed.svg';
import warehouse from '../assets/images/services/warehouse.svg';
import expedite from '../assets/images/services/expedite.svg';

export const serviceItems = {
  '1': {
    id: '1',
    icon: transport,
    title: 'Specialized Transport',
    text:
      'Our specialized transport division built a reputation that prides itself on its ability to haul and deliver a wide variety of loads including oversize, flat bed and temperature-controlled transportation.',
  },
  '2': {
    id: '2',
    icon: warehouse,
    title: 'Warehousing',
    text:
      'Our C-TPAT approved terminal is fully equipped to deal with all aspects of transportation, including cross dock and warehousing services.',
  },
  '3': {
    id: '3',
    icon: temperature,
    title: 'Controlled Temperature',
    text:
      'You can trust our reliable and dependable reefer services that provide the customized service you need to transport your temperature sensitive goods.',
  },
  '4': {
    id: '4',
    icon: flatbed,
    title: 'Flatbed & Stepdeck',
    text:
      'We pride ourselfs with our extensive experience with flatbed and step deck transportation solutions. When it comes to transporting construction materials or loads larger than a van or require modern loading equipment.',
  },
  '5': {
    id: '5',
    icon: expedite,
    title: 'Expedited Upon Request',
    text:
      'We will surpass your expectations in commitment and customer service. Upon request, we will expedite your cargo with innovative logistics to ensure your product arrives on time!',
  },
};
