import React from 'react';
import logo from '../assets/images/logo-footer.svg';
import { observer } from 'mobx-react';
import ToTop from './ToTop';
import {
  ADDRESS,
  PHONE,
  OPERATIONS_MAIL_TITLE,
  OPERATIONS_MAIL_TEXT,
  SAFETY_MAIL_TITLE,
  SAFETY_MAIL_TEXT,
  ACCOUNTS_PAYABLE_MAIL_TITLE,
  ACCOUNTS_PAYABLE_MAIL_TEXT,
  ACCOUNTS_RECEIVABLE_MAIL_TITLE,
  ACCOUNTS_RECEIVABLE_MAIL_TEXT,
  BILLING_MAIL_TITLE,
  BILLING_MAIL_TEXT,
  DISPATCH_MAIL_TITLE,
  DISPATCH_MAIL_TEXT,
  FLATBED_MAIL_TITLE,
  FLATBED_MAIL_TEXT,
  FOR,
  GENERAL_INFO,
  PLEASE_EMAIL,
  GENERAL_EMAIL,
} from '../constants';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer-content">
          <div className="info-box">
            <img src={logo} alt="logo" />
            <div className="info-box-text-wrapper">
              <div className="info-box-text mt20">
                {ADDRESS.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
              <div className="info-box-text mt20">
                {PHONE.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            </div>
          </div>
          <div className="general-box">
            <div className="email-list">
              <div className="email-box">
                <p className="title">{OPERATIONS_MAIL_TITLE}</p>
                <a href={`mailto:${OPERATIONS_MAIL_TEXT}`}>
                  {OPERATIONS_MAIL_TEXT}
                </a>
              </div>
              <div className="email-box">
                <p className="title">{SAFETY_MAIL_TITLE}</p>
                <a href={`mailto:${SAFETY_MAIL_TEXT}`}>{SAFETY_MAIL_TEXT}</a>
              </div>
              <div className="email-box">
                <p className="title">{ACCOUNTS_PAYABLE_MAIL_TITLE}</p>
                <a href={`mailto:${ACCOUNTS_PAYABLE_MAIL_TEXT}`}>
                  {ACCOUNTS_PAYABLE_MAIL_TEXT}
                </a>
              </div>
              <div className="email-box">
                <p className="title">{ACCOUNTS_RECEIVABLE_MAIL_TITLE}</p>
                <a href={`mailto:${ACCOUNTS_RECEIVABLE_MAIL_TEXT}`}>
                  {ACCOUNTS_RECEIVABLE_MAIL_TEXT}
                </a>
              </div>
              <div className="email-box">
                <p className="title">{BILLING_MAIL_TITLE}</p>
                <a href={`mailto:${BILLING_MAIL_TEXT}`}>{BILLING_MAIL_TEXT}</a>
              </div>
              <div className="email-box">
                <p className="title">{DISPATCH_MAIL_TITLE}</p>
                <a href={`mailto:${DISPATCH_MAIL_TEXT}`}>
                  {DISPATCH_MAIL_TEXT}
                </a>
              </div>
              <div className="email-box">
                <p className="title">{FLATBED_MAIL_TITLE}</p>
                <a href={`mailto:${FLATBED_MAIL_TEXT}`}>{FLATBED_MAIL_TEXT}</a>
              </div>
            </div>
            <p className="general-info-mail-box">
              {FOR} <span className="fw700">{GENERAL_INFO}</span> {PLEASE_EMAIL}
              <span>
                <a className="fw700" href={`mailto:${GENERAL_EMAIL}`}>
                  {GENERAL_EMAIL}
                </a>
              </span>
            </p>
          </div>
          <ToTop />
        </div>
      </div>
    </footer>
  );
};

export default observer(Footer);
