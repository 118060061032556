import React from 'react';
import HomePage from '../views/home/Home';
import { getParent, getRoot } from 'mobx-state-tree';
import { HOME } from '../constants';

const PREFIX_PATH = '';
window['getRoot'] = getRoot;
window['getParent'] = getParent;

export const PageRoutes = {
  Home: {
    component: <HomePage />,
    name: 'Home',
    path: `${PREFIX_PATH}/`,
    hooks: {
      beforeEnter(self) {
        const { menuState } = getRoot(self);
        menuState.setActiveItem(HOME);
      },
      beforeExit(self) {},
    },
  },
};
export default PageRoutes;
