import { types } from 'mobx-state-tree';

export const PickupModel = types
  .model('PickupModel', {
    address: types.maybe(types.string),
    city: types.maybe(types.string),
    lat: types.maybe(types.string),
    from_time: types.maybe(types.maybeNull(types.string)),
    due_time: types.maybe(types.maybeNull(types.string)),
    long: types.maybe(types.string),
    name: types.maybe(types.string),
    tooltip_open: false,
    highlighted: false,
  })
  .actions((self) => {
    return {};
  });

export const DeliveryModel = types
  .model('DeliveryModel', {
    address: types.maybe(types.string),
    city: types.maybe(types.string),
    lat: types.maybe(types.string),
    from_time: types.maybe(types.maybeNull(types.string)),
    due_time: types.maybe(types.maybeNull(types.string)),
    long: types.maybe(types.string),
    name: types.maybe(types.string),
    tooltip_open: false,
    highlighted: false,
  })
  .actions((self) => {
    return {};
  });

export const TransportModel = types
  .model('TransportModel', {
    location_name: types.maybe(types.string),
    lat: types.maybe(types.string),
    long: types.maybe(types.string),
    tooltip_open: false,
    highlighted: false,
  })
  .actions((self) => {
    return {};
  });

export const ShipmentModel = types
  .model('ShipmentModel', {
    probil_id: types.identifierNumber,
    pickup_location: types.maybe(PickupModel),
    delivery_location: types.maybe(DeliveryModel),
    transport: types.maybeNull(TransportModel),
    status: types.maybe(types.string),
    tooltip_open: false,
    fullScreenMap: false,
    pinZoomed: types.maybe(types.maybeNull(types.string)),
    isStatusPopupOpen: false,
  })
  .views((self) => {
    return {
      get isDelivered() {
        return self.status === 'DELIVERED';
      },
      get isAtPickup() {
        return self.status === 'SCHEDULED-FOR-PICKUP';
      },
      get isInTransit() {
        return self.status === 'IN-TRANSIT' && !!self.transport;
      },
      get hasTransportObject() {
        return !this.isDelivered && !this.isAtPickup && !!self.transport;
      },
      get isInTransitAndHasNoTransportObject() {
        return self.status === 'IN-TRANSIT' && !self.transport;
      },
    };
  })
  .actions((self) => {
    return {
      setIsStatusPopupOpen(status) {
        self.isStatusPopupOpen = status;
      },
      setPinZoomed(pin) {
        self.pinZoomed = pin;
      },
      setFullScreenMap(status) {
        self.fullScreenMap = status;
      },
      setPickupTooltipHighlighted(status) {
        self.pickup_location.highlighted = status;
      },
      setDeliveryTooltipHighlighted(status) {
        self.delivery_location.highlighted = status;
      },
      setTransportTooltipHighlighted(status) {
        self.transport.highlighted = status;
      },
    };
  });
