import { types } from 'mobx-state-tree';

export const MenuItemModel = types
  .model('MenuItemModel', {
    id: types.identifier,
    title: types.string,
    name: types.string,
    active: types.boolean,
  })
  .actions((self) => {
    return {
      setActive(status) {
        self.active = status;
      },
    };
  });
