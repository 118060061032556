import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import logo from '../assets/images/logo-pentagon-black.svg';
import {
  SHIPMENT_NOT_FOUND_TITLE,
  SHIPMENT_NOT_FOUND_TEXT,
  SHIPMENT_NOT_FOUND_BUTTON,
} from '../constants';

const ShipmentNotFound = () => {
  const {
    homeState: { setShipmentNotFound },
  } = useMst();

  return (
    <div className="shipment-not-found">
      <img className="logo" src={logo} alt="pentagon logo" />
      <div className="flex-column justify-between">
        <div className="text-wrapper">
          <h2 className="mt20">{SHIPMENT_NOT_FOUND_TITLE}</h2>
          <p className="mt20">{SHIPMENT_NOT_FOUND_TEXT}</p>
        </div>
        <button
          className="btn transparent fw700"
          onClick={() => setShipmentNotFound(false)}
        >
          {SHIPMENT_NOT_FOUND_BUTTON}
        </button>
      </div>
    </div>
  );
};

export default observer(ShipmentNotFound);
