import { types } from 'mobx-state-tree';
import { rootInstance } from './RootModel';
import * as Scroll from 'react-scroll';
import { ServiceItemModel } from './ServiceItemModel';
import { serviceItems } from './ServiceItems';
import { LogoItemModel } from './LogoItemModel';
import { logoItems } from './LogoItems';
import { AboutItemModel } from './AboutItemModel';
import { aboutItems } from './AboutItems';
import { ShipmentModel } from './ShipmentModel';
import { SERVICES, PICKUP, TRANSPORT, DELIVERY } from '../constants';

let scroll = Scroll.animateScroll;
let scroller = Scroll.scroller;

export const HomeModel = types
  .model('HomeModel', {
    serviceItems: types.map(ServiceItemModel),
    logoItems: types.map(LogoItemModel),
    aboutItems: types.map(AboutItemModel),
    trackModalOpen: true,
    trackingNumber: types.maybeNull(types.string),
    trackingNumberError: false,
    // userPhoneNumber: types.maybeNull(types.string),
    // userPhoneNumberError: false,
    //START LUKA
    // userEmail: types.maybeNull(types.string),
    // userEmailError: false,
    //END LUKA
    selectedShipment: types.maybeNull(ShipmentModel),
    fetchingInProgress: false,
    shipmentNotFound: false,
  })
  .views((self) => {
    return {
      get serviceItemsArray() {
        return Array.from(self.serviceItems.values());
      },
      get logoItemsArray() {
        return Array.from(self.logoItems.values());
      },
      get aboutItemsArray() {
        return Array.from(self.aboutItems.values());
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        this.setServiceItems(serviceItems);
        this.setLogoItems(logoItems);
        this.setAboutItems(aboutItems);
      },
      async getShipment() {
        this.setFetchingInProgress(true);
        //START LUKA
        // fetch(
        //   `${rootInstance.baseUrl}?probil_id=${encodeURIComponent(self.trackingNumber)}
        //   &email=${encodeURIComponent(self.userEmail)}`,
        // )
        //END LUKA
        fetch(
          `${rootInstance.baseUrl}?probil_id=${encodeURIComponent(self.trackingNumber)}
          &telephone_number=123123123`,
        )
          .then((response) => response.json())
          .then((shipment) => {
            rootInstance.homeState.setSelectedShipment(shipment);
            rootInstance.homeState.setFetchingInProgress(false);
          })
          .catch((e) => {
            rootInstance.homeState.setShipmentNotFound(true);
            rootInstance.homeState.setFetchingInProgress(false);
          });
      },
      setSelectedShipment(item, fullScreen = false) {
        if (item) {
          this.closeTrackModal();
        } else {
          this.resetForm();
          this.openTrackModal();
        }
        if (item) {
          if (
            item.status === 'DELIVER...' ||
            item.status === 'DROP...' ||
            item.status === 'DROPPED' ||
            item.status === 'UNLOADING' ||
            item.status === 'PICKED UP'
          ) {
            item.status = 'IN-TRANSIT';
          } else if (
            item.status === 'PENDING' ||
            item.status === 'PICKUP' ||
            item.status === 'PICKUP...'
          ) {
            item.status = 'SCHEDULED-FOR-PICKUP';
          }
        }
        self.selectedShipment = item;
        if (self.selectedShipment) {
          self.selectedShipment.setPinZoomed(null);
          self.selectedShipment.setIsStatusPopupOpen(true);
          if (self.selectedShipment.hasTransportObject) {
            self.selectedShipment.setPinZoomed(TRANSPORT);
            self.selectedShipment.setTransportTooltipHighlighted(true);
            self.selectedShipment.setFullScreenMap(true);
          } else if (self.selectedShipment.isDelivered) {
            self.selectedShipment.setPinZoomed(DELIVERY);
            self.selectedShipment.setDeliveryTooltipHighlighted(true);
            self.selectedShipment.setFullScreenMap(fullScreen);
          } else if (self.selectedShipment.isAtPickup) {
            self.selectedShipment.setPinZoomed(PICKUP);
            self.selectedShipment.setPickupTooltipHighlighted(true);
            self.selectedShipment.setFullScreenMap(fullScreen);
          } else {
            self.selectedShipment.setFullScreenMap(true);
          }
        }
      },
      setFetchingInProgress(status) {
        self.fetchingInProgress = status;
      },
      setShipmentNotFound(status) {
        self.shipmentNotFound = status;
      },
      validateTrackForm() {
        this.resetFormErrors();
        // const regEmail = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
        // const regPhone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;

        //START LUKA
        // const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // if (!regEmail.test(self.userEmail)) {
        //   this.setUserEmailError(true);
        // } else {
        //   if (self.trackingNumber && self.trackingNumber.length) {
        //     this.getShipment();
        //   } else {
        //     this.setTrackingNumberError(true);
        //   }
        // }
        //END LUKA
        
        if (self.trackingNumber && self.trackingNumber.length) {
          this.getShipment();
        } else {
          this.setTrackingNumberError(true);
        }
      },
      resetForm() {
        // this.setUserPhoneNumber(null);
        //START LUKA
        // this.setUserEmail(null);
        // this.setUserEmailError(false);
        //END LUKA
        this.setTrackingNumber(null);
        this.setTrackingNumberError(false);
      },
      resetFormErrors() {
        // this.setUserPhoneNumberError(false);
        //START LUKA
        //this.setUserEmailError(false);
        //END LUKA
        this.setTrackingNumberError(false);
      },
      setTrackingNumberError(status) {
        self.trackingNumberError = status;
      },
      // START LUKA
      // setUserEmailError(status) {
      //   self.userEmailError = status;
      // },
      //END LUKA
      scrollToTop() {
        scroll.scrollToTop();
      },
      scrollToServices() {
        const section = rootInstance.menuState.menuItemsArray.find((item) => {
          return item.title === SERVICES;
        });
        scroller.scrollTo(section.name, {
          duration: 1000,
          smooth: true,
          offset: -100,
        });
      },
      setServiceItems(items) {
        Object.values(items).forEach((item) => {
          self.serviceItems.put(item);
        });
      },
      setLogoItems(items) {
        Object.values(items).forEach((item) => {
          self.logoItems.put(item);
        });
      },
      setAboutItems(items) {
        Object.values(items).forEach((item) => {
          self.aboutItems.put(item);
        });
      },
      closeTrackModal() {
        self.trackModalOpen = false;
      },
      openTrackModal() {
        self.trackModalOpen = true;
      },
      setTrackingNumber(input) {
        if (input && input.target) {
          self.trackingNumber = input.target.value;
        } else {
          self.trackingNumber = input;
        }
      },
      //START LUKA
      // setUserEmail(input) {
      //   if(input && input.target) {
      //     self.userEmail = input.target.value
      //   } else {
      //     self.userEmail = input;
      //   }
      // },
      //END LUKA
      // setUserPhoneNumber(input) {
      //   if (input && input.target) {
      //     self.userPhoneNumber = input.target.value;
      //   } else {
      //     self.userPhoneNumber = input;
      //   }
      // },
    };
  });
