import React from 'react';
import logo from '../assets/images/logo-pentagon.svg';
import active from '../assets/images/active-dot.png';
import {observer} from 'mobx-react';
import {useMst} from '../state/RootModel';
import * as Scroll from 'react-scroll';
import {CUSTOMER_LOGIN} from "../constants";

let ScrollLink = Scroll.Link;

const Menu = () => {
    const {
        menuState: {
            menuItemsArray,
            mobileMenuOpen,
            toggleMobileMenuOpen,
            setActiveItem,
            handleItemClick,
        },
        homeState: {scrollToTop},
    } = useMst();

    return (
        <div className={`header ${mobileMenuOpen && 'mobile-menu-open'}`}>
            <div className="justify-between align-center">
                <div className="logo-wrapper align-center">
                    <div onClick={scrollToTop}>
                        <img className="w100" src={logo} alt="logo"/>
                    </div>
                </div>
                <div className="links-wrapper isWiderThanMobile">
                    {menuItemsArray.map((item) => {
                        return (
                            <ScrollLink
                                key={item.id}
                                to={item.name}
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={1000}
                                onSetActive={() => setActiveItem(item.title)}
                                className="menu-link"
                                onClick={() => handleItemClick(item.title)}
                            >
                                <span>{item.title}</span>
                                {item.active && (
                                    <img className="active-dot" src={active} alt="active dot"/>
                                )}
                            </ScrollLink>
                        );
                    })}
                    <a
                        className='btn default menu-link track-btn'
                        rel="noreferrer"
                        href={"https://pentagon.freightassist.com/"} target="_blank">
                        {CUSTOMER_LOGIN}
                    </a>
                </div>
                <div className="hamburger-holder isMobileView">
                    <div
                        className={`hamburger ${mobileMenuOpen && 'mobile-menu-open'}`}
                        id="hamburger"
                        onClick={toggleMobileMenuOpen}
                    >
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    <ul
                        className={`mobile-menu ${mobileMenuOpen && 'open'}`}
                        id="mobile-menu"
                    >
                        {menuItemsArray.map((item) => {
                            return (
                                <li key={item.id} className="navlink-wrapper">
                                    <ScrollLink
                                        key={item.id}
                                        to={item.name}
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={1000}
                                        className="mobile-menu-link"
                                    >
                                        <span>{item.title}</span>
                                    </ScrollLink>
                                </li>
                            );
                        })}
                        <li className='navlink-wrapper'>
                            <a
                                className='btn default menu-link track-btn'
                                rel="noreferrer"
                                href={"https://pentagon.freightassist.com/"} target="_blank">
                                {CUSTOMER_LOGIN}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default observer(Menu);
