import React from "react";
import { observer } from "mobx-react";
import { Spin } from "antd";

export const StateRouter = observer(({ router, loading }) => {
  if (router.isLoading)
    return loading ? (
      loading
    ) : (
      <Spin size="large" style={{ height: "100vh" }} className="flex-center" />
    );

  return (
    <div>
      {router.currentView && router.currentView.component
        ? React.cloneElement(router.currentView.component, router.props)
        : "currentView not loaded yet or component is missing"}
    </div>
  );
});

export default StateRouter;
