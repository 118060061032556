import tia from '../assets/images/logos/logo-tia.svg';
import fast from '../assets/images/logos/logo-fast.svg';
import pip from '../assets/images/logos/logo-pip.svg';
import csa from '../assets/images/logos/logo-csa.svg';
import ctpat from '../assets/images/logos/logo-ctpat.svg';
import aeo from '../assets/images/logos/logo-aeo.svg';

export const logoItems = {
  '1': {
    id: '1',
    image: tia,
    title: 'TIA logo',
  },
  '2': {
    id: '2',
    image: fast,
    title: 'FAST logo',
  },
  '3': {
    id: '3',
    image: pip,
    title: 'PIP logo',
  },
  '4': {
    id: '4',
    image: csa,
    title: 'CSA logo',
  },
  '5': {
    id: '5',
    image: ctpat,
    title: 'CTAPT logo',
  },
  '6': {
    id: '6',
    image: aeo,
    title: 'AEO logo',
  },
};
