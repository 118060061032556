import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import { Modal } from 'antd';
import {
  PICKUP_POPUP_TITLE,
  PICKUP_POPUP_TEXT,
  DELIVERY_POPUP_TITLE,
  DELIVERY_POPUP_TEXT,
  TRANSIT_POPUP_TITLE,
  TRANSIT_POPUP_TEXT,
  TRANSIT_FALLBACK_POPUP_TEXT,
  SHIPMENT_POPUP_BUTTON_TEXT,
} from '../constants';
import pickup from '../assets/images/pickup.svg';
import delivery from '../assets/images/delivery.svg';
import transit from '../assets/images/transit.svg';

const ShipmentStatusPopup = () => {
  const {
    homeState: {
      selectedShipment: {
        isStatusPopupOpen,
        isAtPickup,
        isDelivered,
        setIsStatusPopupOpen,
        hasTransportObject,
        delivery_location,
      },
    },
  } = useMst();

  const ETA = `${delivery_location.from_time && delivery_location.from_time} ${
    delivery_location.due_time ? 'to ' + delivery_location.due_time : ''
  }`;

  return (
    <div>
      <Modal
        visible={isStatusPopupOpen}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={425}
        className="shipment-status-popup"
      >
        <div className="p32">
          <div className="flex-column justify-center align-center">
            <img
              src={isAtPickup ? pickup : isDelivered ? delivery : transit}
              alt="status icon"
              height={60}
            />
            <h3 className="fw600 uppercase mt20">
              {isAtPickup
                ? PICKUP_POPUP_TITLE
                : isDelivered
                ? DELIVERY_POPUP_TITLE
                : TRANSIT_POPUP_TITLE}
            </h3>
            <p className="center mt20">
              {isAtPickup
                ? PICKUP_POPUP_TEXT
                : isDelivered
                ? DELIVERY_POPUP_TEXT
                : hasTransportObject
                ? TRANSIT_POPUP_TEXT
                : TRANSIT_FALLBACK_POPUP_TEXT + ETA}
            </p>
            <button
              className={`btn shipment-popup ${
                isAtPickup ? 'pickup' : isDelivered ? 'delivery' : 'transit'
              } mt20 fw600`}
              onClick={() => setIsStatusPopupOpen(false)}
            >
              {SHIPMENT_POPUP_BUTTON_TEXT}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(ShipmentStatusPopup);
