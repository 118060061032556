import React from 'react';
import './styles/styles.scss';
import './App.css';
import PropTypes from 'prop-types';
import { useMst } from './state/RootModel';
import { observer } from 'mobx-react';
import { StateRouter } from './components/StateRouter';
import Header from './components/Header';
import Footer from './components/Footer';
import { HOME } from './constants';

const App = () => {
	const { router } = useMst();
	return (
		<>
			{router && router.currentView && router.currentView.name !== HOME && (
				<Header />
			)}
			<StateRouter router={router} />
			{router && router.currentView && router.currentView.name !== HOME && (
				<Footer />
			)}
		</>
	);
};

App.propTypes = {
	state: PropTypes.object,
};

export default observer(App);
