import React from 'react';
import kamion from '../assets/images/kamion-logo.svg';
import fb from '../assets/images/icon-facebook.svg';
import linkedin from '../assets/images/icon-linkedin.svg';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import { Element } from 'react-scroll';
import {
  PLATFORM_ENTRY_TITLE,
  PLATFORM_ENTRY_TEXT_1,
  PLATFORM_ENTRY_TEXT_2,
  PLATFORM_ENTRY_TEXT_3,
  PENTAGON_LOGISTICS,
  AND,
  TAS_TRANSPORT,
  CONTACT,
  COMING_SOON,
} from '../constants';

const PlatformEntry = () => {
  const {
    menuState: { menuItemsArray },
  } = useMst();

  const section = menuItemsArray.find((item) => {
    return item.title === CONTACT;
  });

  return (
    <Element className="wrapper" name={section.name}>
      <div className="platform-entry">
        <div className="text-wrapper">
          <p className="color-orange uppercase fw700 mb0">{COMING_SOON}</p>
          <h2>{PLATFORM_ENTRY_TITLE}</h2>
          <p>{PLATFORM_ENTRY_TEXT_1}</p>
          <p className="mb0">{PLATFORM_ENTRY_TEXT_2}</p>
          <p>
            {PLATFORM_ENTRY_TEXT_3}{' '}
            <span className="fw600 color-error">{PENTAGON_LOGISTICS}</span>{' '}
            {AND} <span className="fw600 color-pickup">{TAS_TRANSPORT}.</span>
          </p>
          <div className="flex">
            <a href="https://www.facebook.com/Kamion-SCM-103180471834439/">
              <img src={fb} alt="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/kamionscm/"
              className="ml10"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className="img-wrapper flex-center">
          <img src={kamion} alt="kamion logo" />
        </div>
      </div>
    </Element>
  );
};

export default observer(PlatformEntry);
