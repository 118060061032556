import { types } from 'mobx-state-tree';
import { rootInstance } from './RootModel';
import { CONTACT } from '../constants';

export const WindowStateModel = types
  .model('WindowState', {
    pageHeight: types.maybeNull(types.number),
    scrollPosition: types.maybeNull(types.number),
    viewportHeight: types.maybeNull(types.number),
  })
  .actions((self) => {
    return {
      afterCreate() {
        window.addEventListener('scroll', () => {
          this.setPageHeight(document.getElementById('root').scrollHeight);
          this.setScrollPosition(window.scrollY);
          this.setViewportHeight(window.innerHeight);
          this.isAtBottomOfPage();
        });
      },
      setPageHeight(height: number) {
        self.pageHeight = height;
      },
      setScrollPosition(y: number) {
        self.scrollPosition = y;
      },
      setViewportHeight(height: number) {
        self.viewportHeight = height;
      },
      isAtBottomOfPage() {
        if (self.pageHeight !== null) {
          const pixelsFromBottom =
            self.pageHeight - self.scrollPosition - self.viewportHeight;
          if (pixelsFromBottom === 0) {
            rootInstance.menuState.setActiveItem(CONTACT);
          }
        }
      },
      isMobileView() {
        return window.innerWidth < 768;
      },
      isMobileOrTablet() {
        return window.innerWidth <= 1024;
      },
    };
  });
