import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const Logos = () => {
  const sliderSettings = {
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
  };
  const {
    homeState: { logoItemsArray },
  } = useMst();

  return (
    <div className="logos">
      <div className="wrapper">
        <div className="isWiderThanMobile justify-between logos-inline">
          {logoItemsArray.map((logo) => {
            return (
              <img
                key={logo.id}
                className="logo-img"
                src={logo.image}
                alt={logo.title}
              />
            );
          })}
        </div>
        <div className="isMobileView logos-slider">
          <Slider {...sliderSettings}>
            {logoItemsArray.map((logo) => {
              return (
                <img
                  key={logo.id}
                  className="logo-img"
                  src={logo.image}
                  alt={logo.title}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default observer(Logos);
