import React from 'react';
import { observer } from 'mobx-react';

const ServiceCard = (props) => {
  const service = { ...props.service };
  return (
    <div className="service-card">
      <div className="icon-wrapper">
        {service.icon && <img src={service.icon} alt={service.title} />}
      </div>
      <div className="text-wrapper">
        <h2>{service.title}</h2>
        <p>{service.text}</p>
      </div>
    </div>
  );
};

export default observer(ServiceCard);
