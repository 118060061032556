import { Instance, types } from 'mobx-state-tree';
import { WindowStateModel } from './WindowStateModel';
import { createContext, useContext } from 'react';
import { MenuModel } from './MenuModel';
import { HomeModel } from './HomeModel';
import { RouterStore } from './RouterStore';
import { PageRoutes } from '../utils/PageRoutes';
import { startRouter } from './RouterStore';
import { observable } from 'mobx';

export const production =
  'https://t0adpc28w7.execute-api.us-east-1.amazonaws.com/production/probils';

export const baseUrl = production;

export const imageBaseUrl = process.env.REACT_APP_IMAGE_BASEURL;

export const websiteImageBaseUrl = '';

export const environment = process.env.REACT_APP_ENV;

export const websiteUrl = '';

export const RootModel = types
  .model('Root', {
    router: RouterStore,
    windowState: WindowStateModel,
    menuState: MenuModel,
    homeState: HomeModel,
    baseUrl,
    // imageBaseUrl,
    // environment,
    // websiteUrl,
    // websiteImageBaseUrl,
  })
  .actions((self) => {
    return {};
  });

export const mobxStore = observable({
  name: 'mobx',
});

export type MobxStoreInstance = typeof mobxStore;
export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);
const MobxStoreContext = createContext<null | MobxStoreInstance>(null);
export const MobxProvider = MobxStoreContext.Provider;
export const Provider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store as RootInstance;
}
export function useMobx() {
  const store = useContext(MobxStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store as MobxStoreInstance;
}

export const rootInstance = RootModel.create({
  menuState: {
    menuItems: {},
  },
  router: {
    views: PageRoutes,
  },
  windowState: WindowStateModel.create(),
  homeState: {},
  baseUrl,
  // imageBaseUrl,
  // environment,
});
startRouter(rootInstance.router);
export interface RootType extends Instance<typeof rootInstance> {}
