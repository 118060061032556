import { types } from 'mobx-state-tree';

export const ServiceItemModel = types
  .model('ServiceItemModel', {
    id: types.identifier,
    icon: types.string,
    title: types.string,
    text: types.string,
  })
  .actions((self) => {
    return {};
  });
