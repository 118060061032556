import React from 'react';
import { observer } from 'mobx-react';
import logo from '../assets/images/logo-pentagon-black.svg';
import { Input, Form } from 'antd';
import ShipmentNotFound from './ShipmentNotFound';
import Loader from './Loader';
import { useMst } from '../state/RootModel';
import {
  TRACK_MODAL_TEXT,
  TRACK_MODAL_TEXT_SHORT,
  TRACK,
  TRACKING_INPUT_PLACEHOLDER,
  TRACK_MODAL_NUMBER_ERROR_TEXT,
  //START LUKA
  // TRACKING_INPUT_EMAIL_PLACEHOLDER,
  // EMAIL_ERROR_TEXT,
  //END LUKA
} from '../constants';

const TrackModal = () => {
  const {
    homeState: {
      trackModalOpen,
      trackingNumber,
      setTrackingNumber,
      validateTrackForm,
      trackingNumberError,
      // START LUKA
      // userEmail,
      // setUserEmail,
      // userEmailError,
      // END LUKA
      shipmentNotFound,
      fetchingInProgress,
    },
  } = useMst();

  const onSubmit = (e) => {
    e.preventDefault();
    validateTrackForm();
  };

  return (
    <div className={`track-modal ${trackModalOpen && 'open'}`}>
      {fetchingInProgress ? (
        <Loader />
      ) : shipmentNotFound ? (
        <ShipmentNotFound />
      ) : (
        <div>
          <img className="logo" src={logo} alt="logo" />
          <p className="left isWiderThanMobile">{TRACK_MODAL_TEXT}</p>
          <p className="left isMobileView">{TRACK_MODAL_TEXT_SHORT}</p>
          <Form onSubmit={onSubmit} className="mt20">
            {/* START LUKA */}
            {/* <Form.Item>
              <Input
                onChange={setUserEmail}
                placeholder={TRACKING_INPUT_EMAIL_PLACEHOLDER}
                type={'text'}
                value={userEmail}
                className="tracking-input"
              />
              {userEmailError && (
                <p className="error-text">
                  {EMAIL_ERROR_TEXT}
                </p>
              )}
            </Form.Item> */}
            {/* END LUKA */}
            <Form.Item>
              <Input
                onChange={setTrackingNumber}
                placeholder={TRACKING_INPUT_PLACEHOLDER}
                type={'text'}
                maxLength={9}
                value={trackingNumber}
                className="tracking-input"
              />
              {trackingNumberError && (
                <p className="error-text">{TRACK_MODAL_NUMBER_ERROR_TEXT}</p>
              )}
            </Form.Item>
            <button className="btn default track-btn" type={'submit'}>
              {TRACK}
            </button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default observer(TrackModal);
