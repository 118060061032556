import React from 'react';
import { observer } from 'mobx-react';
import PlatformEntry from '../../components/PlatformEntry';
import Services from '../../components/Services';
import Logos from '../../components/Logos';
import About from '../../components/About';
import Hero from '../../components/Hero';

const HomePage = () => {
	const showComponents = false;
	return (
		<div id="home" className="page home-page">
			<Hero />
			{showComponents && (
				<>
					<Services />
					<Logos />
					<About />
					<PlatformEntry />
				</>
				)
			}
		</div>
	);
};

export default observer(HomePage);
