import React from 'react';
import { observer } from 'mobx-react';
import TrackModal from './TrackModal';
import StopTrackingModal from './StopTrackingModal';
import Map from './Map';
import { useMst } from '../state/RootModel';
import { Element } from 'react-scroll';
import { HOME } from '../constants';
import down from '../assets/images/arrow-down.svg';
import ShipmentInfoComponent from './ShipmentInfo';
import ShipmentStatusPopup from './ShipmentStatusPopup';

const Hero = () => {
  const {
    menuState: { menuItemsArray },
    homeState: { scrollToServices, selectedShipment },
  } = useMst();
  const section = menuItemsArray.find((item) => {
    return item.title === HOME;
  });
  return (
    <Element className="hero" name={section.name}>
      {selectedShipment && <ShipmentInfoComponent />}
      <Map />
      <TrackModal />
      {selectedShipment && <ShipmentStatusPopup />}
      {selectedShipment && <StopTrackingModal />}
      {!selectedShipment && section.title && section.title !== HOME &&(
        <img
          className="scroll-down-arrow"
          src={down}
          alt="scroll down"
          onClick={scrollToServices}
        />
      )}
    </Element>
  );
};

export default observer(Hero);
