import React from 'react';
import background from '../assets/images/services.png';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import ServiceCard from './ServiceCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Element } from 'react-scroll';
import { SERVICES } from '../constants';

const Services = () => {
  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const {
    homeState: { serviceItemsArray },
    menuState: { menuItemsArray },
  } = useMst();

  const chunkedArray = serviceItemsArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  const section = menuItemsArray.find((item) => {
    return item.title === SERVICES;
  });

  return (
    <Element
      className="services section"
      name={section.name}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="wrapper">
        <div className="isWiderThanTablet services-content">
          {serviceItemsArray.map((service) => {
            return <ServiceCard key={service.id} service={service} />;
          })}
        </div>
        <div className="isTabletAndSmaller">
          <Slider {...sliderSettings}>
            {chunkedArray.map((arr, index) => {
              return (
                <div key={index} className="doubleSlide">
                  <ServiceCard service={arr[0]} />
                  {arr.length > 1 && <ServiceCard service={arr[1]} />}
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </Element>
  );
};

export default observer(Services);
