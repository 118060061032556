import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import truck from '../assets/images/truck.svg';
import truckActive from '../assets/images/truck-active.svg';
import zoomOut from '../assets/images/zoom-out.svg';
import {
  CURRENT_LOCATION,
  STATUS,
  PROBILL_NO,
  PICKUP,
  DELIVERY,
  PICKUP_TIME,
  DELIVERY_TIME,
  ZOOM_OUT,
  TRANSPORT,
} from '../constants';

const ShipmentInfoComponent = () => {
  const {
    homeState: { selectedShipment, setSelectedShipment },
    windowState: { isMobileView },
  } = useMst();

  const currentCoord = selectedShipment.transport
    ? `${Number(selectedShipment.transport.lat).toFixed(6)}, ${Number(
        selectedShipment.transport.long,
      ).toFixed(6)}`
    : null;

  const pickupCoord = selectedShipment.pickup_location
    ? `${Number(selectedShipment.pickup_location.lat).toFixed(6)}, ${Number(
        selectedShipment.pickup_location.long,
      ).toFixed(6)}`
    : null;

  const deliveryCoord = selectedShipment.delivery_location
    ? `${Number(selectedShipment.delivery_location.lat).toFixed(6)}, ${Number(
        selectedShipment.delivery_location.long,
      ).toFixed(6)}`
    : null;

  const zoomPin = (type) => {
    if (!isMobileView()) {
      selectedShipment.setPinZoomed(null);
      selectedShipment.setFullScreenMap(false);
      selectedShipment.setPinZoomed(type);
    }
  };

  const zoomMapOut = () => {
    setSelectedShipment(selectedShipment, true);
  };

  const googleMapsLink = 'https://www.google.com/maps?q=';

  return (
    selectedShipment && (
      <div className="shipment-info-wrapper wrapper">
        <div className="shipment-info-content">
          {!selectedShipment.fullScreenMap && (
            <div className="w100">
              <div
                className="zoom-out-wrapper isWiderThanMobile"
                onClick={zoomMapOut}
              >
                <img src={zoomOut} alt={ZOOM_OUT} />
                <span className="ml10 color-zoom-out fw600">{ZOOM_OUT}</span>
              </div>
            </div>
          )}
          {selectedShipment.isInTransitAndHasNoTransportObject && (
            <div className="tooltip status-tooltip">
              <span className="title mr5">{STATUS}</span>
              <span className="data fw600">{selectedShipment.status}</span>
            </div>
          )}
          {selectedShipment.pickup_location && (
            <div
              className={`tooltip pickup ${
                selectedShipment.pickup_location.highlighted ? 'active' : ''
              }`}
              onClick={() => zoomPin(PICKUP)}
            >
              <div className="align-center">
                <span className="title mr5">{PICKUP}:</span>
                <a
                  className="title pointer coordinates"
                  href={googleMapsLink + pickupCoord}
                  target="_blank"
                  rel="noreferrer"
                >
                  {pickupCoord}
                </a>
              </div>
              <p className="location">
                {selectedShipment.pickup_location.address},{' '}
                {selectedShipment.pickup_location.city}
              </p>
              <p className="name">{selectedShipment.pickup_location.name}</p>
              <p className="time">
                {PICKUP_TIME}
                {': '}
                {selectedShipment.pickup_location.from_time && (
                  <span>{selectedShipment.pickup_location.from_time} </span>
                )}
                {selectedShipment.pickup_location.due_time && (
                  <span>{`to ${selectedShipment.pickup_location.due_time}`}</span>
                )}
              </p>
            </div>
          )}
          {selectedShipment.hasTransportObject && (
            <div
              className={`tooltip current align-center justify-between ${
                selectedShipment.transport.highlighted ? 'active' : ''
              }`}
              onClick={() => zoomPin(TRANSPORT)}
            >
              <div className="tooltip-content">
                <p className="title">
                  {PROBILL_NO}
                  <span className="data">{selectedShipment.probil_id}</span>
                </p>
                <p className="title">
                  {STATUS}
                  <span className="data">{selectedShipment.status}</span>
                </p>
                <p className="title">{CURRENT_LOCATION}</p>
                <p className="data fw600 underline color-black">
                  {selectedShipment.transport.location_name}
                </p>
                <a
                  className="title pointer coordinates"
                  href={googleMapsLink + currentCoord}
                  target="_blank"
                  rel="noreferrer"
                >
                  {currentCoord}
                </a>
              </div>
              <img
                src={
                  selectedShipment.transport.highlighted ? truckActive : truck
                }
                alt="status icon"
                width="28px"
              />
            </div>
          )}
          {selectedShipment.delivery_location && (
            <div
              className={`tooltip delivery ${
                selectedShipment.delivery_location.highlighted ? 'active' : ''
              }`}
              onClick={() => zoomPin(DELIVERY)}
            >
              <div className="align-center">
                <span className="title mr5">{DELIVERY}:</span>
                <a
                  className="title pointer coordinates"
                  href={googleMapsLink + deliveryCoord}
                  target="_blank"
                  rel="noreferrer"
                >
                  {deliveryCoord}
                </a>
              </div>
              <p className="location">
                {selectedShipment.delivery_location.address},{' '}
                {selectedShipment.delivery_location.city}
              </p>
              <p className="name">{selectedShipment.delivery_location.name}</p>
              <p className="time">
                {DELIVERY_TIME}
                {': '}
                {selectedShipment.delivery_location.from_time && (
                  <span>{selectedShipment.delivery_location.from_time} </span>
                )}
                {selectedShipment.delivery_location.due_time && (
                  <span>{`to ${selectedShipment.delivery_location.due_time}`}</span>
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default observer(ShipmentInfoComponent);
