import { types } from 'mobx-state-tree';

export const LogoItemModel = types
  .model('LogoItemModel', {
    id: types.identifier,
    image: types.string,
    title: types.string,
  })
  .actions((self) => {
    return {};
  });
